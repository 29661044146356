import { Shipment } from '@core/models/shipment.model';
import { MapItem } from '@capturum/ui/api';
import { RejectedShipmentInformation } from '@store/mobile/rejected-shipments/rejected-shipments-state.model';

export class SetShipmentInformation {
  public static readonly type = '[Reject Shipments] Set rejected shipment information';

  constructor(public information: RejectedShipmentInformation[]) {}
}

export class SetRejectedShipment {
  public static readonly type = '[Reject Shipments] Set shipment';

  constructor(
    public shipment: Shipment,
    public information?: RejectedShipmentInformation[],
  ) {}
}

export class ScanRejectedShipment {
  public static readonly type = '[Reject Shipments] Scanned rejected shipment';

  constructor(public shipmentId: MapItem) {}
}

export class SetRejectedReasonsAndBoxes {
  public static readonly type = '[Reject Shipments] Set rejected reasons and boxes';

  constructor(
    public boxStockId: string,
    public reasonId: string,
    public amount: number,
  ) {}
}

export class ResetRejectedReasonsAndBoxes {
  public static readonly type = '[Reject Shipments] Reset rejected reasons and boxes';

  constructor(
    public boxStockId: string,
    public reasonId: string,
  ) {}
}

export class ResetRejectedShipments {
  public static readonly type = '[Reject Shipments] Reset rejected Shipments';
}
